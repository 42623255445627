import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

// pages
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Requests from '../views/Requests.vue'
import PrivateRooms from '../views/PrivateRooms.vue'
import PublicRooms from '../views/PublicRooms.vue'
import Room from '../views/Room.vue'
import StudentsOfRoom from '../views/StudentOfRoom.vue'
import Lesson from '../views/Lesson.vue'
import Settings from '../views/Settings.vue'
import LandingPage from '../views/LandingPage.vue'
import Search from '../views/Search.vue'

import routeAuth from "../utils/RoutingHelper.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/requests',
    name: 'requests',
    component: Requests,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/private-rooms',
    name: 'privateRooms',
    component: PrivateRooms,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/public-rooms',
    name: 'publicRooms',
    component: PublicRooms,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/room/:roomID',
    name: 'room',
    component: Room,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/room/:roomID/students',
    name: 'studentsOfRoom',
    component: StudentsOfRoom,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/lesson/:lessonID',
    name: 'lesson',
    component: Lesson,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/search/:search',
    name: 'search',
    component: Search
  },
  {
    path: '/',
    name: 'landingPage',
    component: LandingPage
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: routeAuth.isLoggedIn,
  },
]
const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
