<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="rooms-layout">
      <rooms></rooms>
    </div>
 </div>
</template>

<script>
  import store from "../store/index.js"
  import rooms from "../components/PrivateRooms.vue"
  export default {
    name: 'Home',
    data() {
      return {
        alertCountdown: 0,
        alertType: '',
        alertText: ''
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
    },
    components: {
      rooms
    }
  }
</script>
<style scoped>
  .rooms-layout{
    min-height: 86vh;
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
  }
</style>
