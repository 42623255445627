const getters  = {
  authToken(state) {
    return state.token
  },
  username(state) {
    return state.user_name
  },
  accoutType(state) {
    return state.account_type
  },
  getImage(state){
    return state.image
  },
  getUserID(state){
    return state.userID
  }
}
export default getters 
