<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="search-layout">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <div class="public-rooms">
            <div class="div-hdr" >
              <h5><b>نتائج البحث عن : {{this.$route.params.search}}</b></h5>
            </div>
            <div class="search-body">
              <div>
                <div class="classes">
                  <div class="text-center text-danger my-2" v-show="isLoading">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <div class="result-col" v-if="lessonsSearchResults.length == 0 && roomsSearchResults.length == 0" v-show="!isLoading">
                    <div class="pt-5 no-content" style="text-align:center">
                      <h5> لا يوجد نتائج</h5>
                    </div>
                  </div>
                  <div v-else>
                    <h6><span v-if="lessonsSearchResults.length > 0"><b>الدروس</b></span></h6>
                    <div class="result-col" v-for="(result, lessonIndex) in lessonsSearchResults" :key="lessonIndex" v-show="!isLoading">
                      <b-row align-v="center" @click="goToLesson(result.id)">
                        <b-col lg="12" md="12" sm="12">
                          <b-media vertical-align="center" class="pr-2 result-col-click">
                            <h5 class="mt-0 mb-2">{{result.name}}</h5>
                            <p>{{result.description}}</p>
                          </b-media>
                        </b-col>
                      </b-row>
                    </div>
                    <br>
                    <h6><span v-if="roomsSearchResults.length > 0"><b>الفصول</b></span></h6>
                    <div class="result-col" v-for="(result, roomIndex) in roomsSearchResults" :key="roomIndex" v-show="!isLoading">
                      <b-row align-v="center" @click="goToRoom(result.id)">
                        <b-col lg="12" md="12" sm="12">
                          <b-media vertical-align="center" class="pr-2 result-col-click">
                            <h5 class="mt-0 mb-2">{{result.name}}</h5>
                            <p>{{result.subject}}</p>
                          </b-media>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"

  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        roomsSearchResults: [],
        lessonsSearchResults: []
      }
    },
    watch: {
      '$route.params.search': function () {
        this.loadData();
      }
    },
    methods: {
      goToRoom(id) {
        this.$router.push({
          name: "room",
          params: {
            roomID: id
          }
        })
      },
      goToLesson(id) {
        this.$router.push({
          name: "lesson",
          params: {
            lessonID: id
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      loadData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.roomsSearchResults = response.data.data.rooms;
          this.lessonsSearchResults = response.data.data.lessons;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/search?name=' + this.$route.params.search, true, null , successCallback, errorCallback);
      }
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.isLoading = true;
      this.loadData();
    }
  }
</script>
<style scoped>
  .search-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline !important;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .div-hdr .title{
    color: rgb(155, 155, 155);
    font-size: 15px;
    padding-top: 10px;
  }
  .classes{
    margin-top: 50px;
  }
  .video-details{
    font-weight: bold;
    font-size: 15px;
    width: 70%;
    margin-top: 20px;
  }
  .search-resulu-div{
    margin-bottom: 50px;
    padding: 0px;
  }
  .result-col{
    margin: 15px 0px;
    padding: 20px 10px 20px 10px;
    border-radius: 5px;
    background-color: #fff;
    transition: 0.3s;
  }
  .result-col:hover{
    cursor: pointer;
    box-shadow: 3px 4px 5px rgb(211, 211, 211);
  }
  .result-col .image{
    margin-left: 15px;
    width: 40px !important;
    height: 40px !important;
  }
  .result-col h5{
    font-size: 16px;
    font-weight: 600;
    padding-top: 7px;
  }
  .result-col p{
    font-size: 14px;
    color: rgb(155, 155, 155);
  }
  .result-col .action-buttons{
    float: left;
  }
  .delete-icon{
    color: #5e3fd0;
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  .connects-col{
    min-height: 274px !important;
  }
  .connected-users-col{
    padding: 7px;
    padding-top: 10px !important;
  }
  .fa-circle{
    color: rgb(2, 179, 2);
    font-size: 12px;
  }
  @media (max-width: 767px) {
    .room-col{
      padding-left: 20px;
    }
    .room-col .action-buttons{
      margin-top: 20px;
    }
    .result-col{
      padding-left: 20px;
    }
    .result-col .action-buttons{
      margin-top: 20px;
    }
    .video-details{
      width: 100%;
    }
  }
  .video-details{
    font-weight: bold;
    font-size: 15px;
    width: 70%;
    margin-top: 20px;
    padding-right: 5px;
    margin-bottom: 70px;
  }
  @media (max-width: 767px) {
    .video-details{
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .search-layout{
      margin-top: 50px;
    }
  } 
</style>
