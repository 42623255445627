<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="settings-layout">
      <div class="public-rooms">
        <div class="div-hdr">
          <h5><b>الإعدادات</b></h5>
        </div>
        <div class="settings-body">
          
          <div class="settings-col">
            <b-card no-body>
              <div class="text-center text-danger my-2" v-show="isLoading">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <b-tabs pills card :vertical="isVertical" v-show="!isLoading">
                <b-tab title="البيانات الشخصية" active>
                  <b-card-text>
                    <b-form ref="form" @submit.stop.prevent="addLesson">
                      <b-container class="bv-example-row">
                        <b-row>
                          <b-col lg="3" md="12">
                            الاسم
                          </b-col>
                          <b-col lg="9" md="12">
                            <p class="form-data-label" v-if="!editFlag">{{form.full_name}}</p>
                            <b-form-group v-else id="group-admin-add-full_name" label-for="admin-add-full_name">
                              <b-form-input
                                id="admin-add-full_name"
                                name="admin-add-full_name"
                                v-model="$v.form.full_name.$model"
                                :state="validateState('full_name')"
                                aria-describedby="input-add-full_name"
                              ></b-form-input>
                              <b-form-invalid-feedback id="input-add-full_name">
                                الاسم مطلوب
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="3" md="12">
                            اسم المستخدم
                          </b-col>
                          <b-col lg="9" md="12">
                            <p class="form-data-label" v-if="!editFlag">{{form.user_name == null ? '-' : form.user_name}}</p>
                            <b-form-group v-else id="group-admin-add-user_name" label-for="admin-add-user_name">
                              <b-form-input
                                id="admin-add-user_name"
                                name="admin-add-user_name"
                                v-model="$v.form.user_name.$model"
                                :state="validateState('user_name')"
                                aria-describedby="input-add-user_name"
                              ></b-form-input>
                              <b-form-invalid-feedback id="input-add-user_name">
                                اسم المستخدم مطلوب
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="3" md="12">
                            البريد الالكتروني
                          </b-col>
                          <b-col lg="9" md="12">
                            <p class="form-data-label" v-if="!editFlag">{{form.email}}</p>
                            <b-form-group v-else id="group-admin-add-email" label-for="admin-add-email">
                              <b-form-input
                                id="admin-add-email"
                                name="admin-add-email"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-add-email"
                              ></b-form-input>
                              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.required'>
                                البريد الالكتروني مطلوب
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback id="input-add-email" v-if='!$v.form.email.email'>
                                البريد الالكتروني غير صالح - a@b.com
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="3" md="12">
                          رقم الهاتف
                          </b-col>
                          <b-col lg="9" md="12">
                            <p class="form-data-label" v-if="!editFlag">{{form.phone}}</p>
                            <b-form-group v-else id="group-admin-add-phone" label-for="admin-add-phone">
                              <b-form-input
                                id="admin-add-phone"
                                name="admin-add-phone"
                                v-model="$v.form.phone.$model"
                                :state="validateState('phone')"
                                aria-describedby="input-add-phone"
                              ></b-form-input>
                              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.required'>
                                رقم المحمول مطلوب
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.numeric'>
                                رقم المحمول غير صالح - يجب ان يحتوي على ارقام فقط
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.form.phone.minLength || !$v.form.phone.maxLength'>
                                رقم المحمول غير صالح - يتكون من 11 رقما
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="editFlag">
                          <b-col lg="3" md="12">
                          الصورة
                          </b-col>
                          <b-col lg="9" md="12">
                            <b-form-group id="admin-add-image" label-for="admin-add-image">
                              <b-form-file
                                type="file"
                                id="admin-add-image"
                                name="admin-add-image"
                                v-model="$v.form.image.$model"
                                :state="validateState('image')"
                                aria-describedby="admin-add-image"
                              ></b-form-file>
                              <b-form-invalid-feedback id="admin-add-image">
                                  الملف مطلوب
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form>  
                  <div>
                    <b-row>
                        <b-col lg="3" md="12">
                        </b-col>
                        <b-col lg="9" md="12">
                          <div class="action-btns"> 
                            <b-button v-if="!editFlag" class="" type="button" @click="openEditFlag()" variant="primary">تعديل</b-button>  
                            <b-button v-if="editFlag" class="ml-3" type="submit" @click="cancelEditing()" variant="light">الغاء</b-button>
                            <b-button v-if="editFlag" type="submit" @click="editUserData()" variant="primary">حفظ</b-button>
                          </div>
                        </b-col>
                    </b-row>
                  </div>
                  </b-card-text
                ></b-tab>
                <b-tab title="تغيير كلمة المرور">
                  <b-card-text>
                    <b-form ref="form" @submit.stop.prevent="changePassForm">
                      <b-container class="bv-example-row">
                        <b-row>
                          <b-col lg="3" md="12">
                            كلمة المرور الجديدة
                          </b-col>
                          <b-col lg="9" md="12">
                            <b-form-group id="group-admin-changePass-newPass" label-for="admin-changePass-newPass">
                              <b-form-input
                                type="password"
                                id="admin-changePass-newPass"
                                name="admin-changePass-newPass"
                                v-model="$v.changePass.newPass.$model"
                                :state="validatePasswordState('newPass')"
                                aria-describedby="input-changePass-newPass"
                              ></b-form-input>
                              <b-form-invalid-feedback id="input-changePass-newPass" v-if='!$v.changePass.newPass.required'>
                                كلمة المرور الجديدة مطلوبة
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback id="input-add-phone" v-if='!$v.changePass.newPass.minLength'>
                              كلمة المرور يجب ان تكون 8 احرف على الاقل
                            </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form>
                    <div>
                      <b-row>
                        <b-col lg="3" md="12">
                        </b-col>
                        <b-col lg="9" md="12">
                          <div class="action-btns"> 
                            <b-button type="submit" @click="editUserPasswordData()" variant="primary">تغيير كلمة المرور</b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
      
    </div>
 </div>
</template>

<script>
  import store from "../store/index.js"
  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
  import { sendRequest }  from "../utils/RequestHelper.js"
  import axios from 'axios';

  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        isVertical: true,
        editFlag: false,
        form: {
          full_name: null,
          user_name: null,
          email: null,
          phone: null,
          image: null
        },
        changePass: {
          newPass: null
        },
        tempForm: {
          full_name: null,
          user_name: null,
          email: null,
          phone: null
        }
      }
    },
    validations: {
      form: {
        full_name: {
          required
        },
        user_name: {
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          numeric,
          minLength: minLength(11),
          maxLength: maxLength(11),
        },
        image: {

        }
      },
      changePass: {
        newPass: {
          required,
          minLength: minLength(8)
        }
      }
    },
    mounted() {
      this.resizeNavBar();
      window.addEventListener("resize", this.resizeNavBar);
    },
    methods: {
      resizeNavBar() {
        if (window.innerWidth <= 850) {
          this.isVertical = false;
        }
        else{
          this.isVertical = true;
        }
      },
      openEditFlag() {
        this.editFlag = true;
        this.tempForm.full_name = this.form.full_name;
        this.tempForm.user_name = this.form.user_name;
        this.tempForm.email = this.form.email;
        this.tempForm.phone = this.form.phone;
      },
      cancelEditing() {
        this.editFlag = false;
        this.form.full_name = this.tempForm.full_name;
        this.form.user_name = this.tempForm.user_name;
        this.form.email = this.tempForm.email;
        this.form.phone = this.tempForm.phone;
      },
      editUserData() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.uploadFile();
      },
      uploadFile() {
        var formData = new FormData();
        if(this.form.image) {
          formData.append("file", this.form.image);
          formData.append("folder_name", "teachers");
          axios.post(process.env.VUE_APP_API_URL + '/admin/upload-file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.form.image = response.data;
            this.changeData();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          });
        }
        else {
          delete this.form.image;
          this.changeData();
        }
      },
      changeData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم تعديل البيانات بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          store.dispatch("SET_USERNAME", response.data.data.full_name);
          store.dispatch("SET_IMAGE", response.data.data.image);
          this.editFlag = false;
          this.isLoading = false;
          this.$router.push({
            name: "home"
          })
          this.$router.push({
            name: "settings"
          })

        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/update-account', true, this.form , successCallback, errorCallback);
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      validatePasswordState(name) {
        const { $dirty, $error } = this.$v.changePass[name];
        return $dirty ? !$error : null;
      },
      editUserPasswordData() {
        this.$v.changePass.$touch();
        if (this.$v.changePass.$anyError) {
          return;
        }
        this.changePassword();
      },
      changePassword() {
        this.isLoading = true;
        var successCallback = () => {
          this.alertType = "success"
          this.alertText = "تم تعديل كلمة المرور بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/update-account', true, {'password': this.changePass.newPass} , successCallback, errorCallback);
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      loadData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.form = response.data.data;
          this.isLoading = false;
          this.form.image = null;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          store.dispatch("LOGOUT");
          this.$router.push({
            name: "home"
          })
        };
        sendRequest('get', '/teacher/get-account', true, null , successCallback, errorCallback);
      }
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.loadData();
    }
  }
</script>
<style scoped>
  .settings-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
    height: 86vh;
  }
  div.settings-layout div.settings-col div.tabs:first-child{
    background-color: white !important;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline;
  }
  .settings-body{
    padding: 30px 10px;
  }
  .action-btns{
    float: left;
    padding-left: 15px;
  }
  .form-data-label{
    font-size: 14px;
    padding-top: 8px;
    color: rgb(155, 155, 155);
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  @media (max-width: 992px) {
    .form-group{
      margin-top: 10px;
    }
    .action-btns{
      margin-top: 10px;
      float: left;
      padding-left: 15px;
    }
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .settings-layout{
      margin-top: 50px;
    }
  } 
</style>
