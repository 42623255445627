<template>
    
  <div class="private-rooms">
    <div class="div-hdr">
      <h5><b>الغرف الخاصة</b></h5>
      <b-button v-show="!isLoading" variant="primary" @click="openNewRoomModal()"><i class="fas fa-plus pl-2"></i>طلب غرفة جديدة</b-button>
    </div>
    <div class="text-center text-danger my-2" v-show="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <div class="public-rooms-body" v-show="!isLoading">
      <b-row>
        <b-col lg="12" md="12" sm="12" v-if="privateRooms.length == 0">
          <div class="pt-5 no-content" style="text-align:center">
            <img src="../assets/images/data-not-found.png">
            <h4>لا يوجد غرف خاصة</h4>
          </div>
        </b-col>
        <b-col lg="3" md="6" sm="6" v-else v-for="(room, index) in privateRooms" :key="index">
          <div>
            <b-card no-body class="overflow-hidden room-card" @click="goToRoom(room)">
              <div class="live-room" v-if="room.isLive">
                <span><span class="live-txt">Live</span><i class="fas fa-circle live-icon"></i></span>
              </div>
              <div class="card-img">
                <img :src="room.image" alt="Image" class="rounded-0">
              </div>
              <b-card-body :title="room.name">
                <b-card-text>
                  {{room.subject}}
                </b-card-text>
              </b-card-body>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="newRoomModal" centered title="إضافة غرقة خاصة" size="lg">
      
      <b-form ref="form" @submit.stop.prevent="addRoom">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-name" label="اسم الغرفة" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-subject" label="المادة" label-for="admin-add-subject">
                <b-form-input
                  id="admin-add-subject"
                  name="admin-add-subject"
                  v-model="$v.form.subject.$model"
                  :state="validateState('subject')"
                  aria-describedby="input-add-subject"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-subject">
                  اسم المادة مطلوبة
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="group-add-image" label="الصورة" label-for="add-image">
                <b-form-file
                  type="file"
                  id="add-image"
                  name="add-image"
                  v-model="$v.form.image.$model"
                  :state="validateState('image')"
                  aria-describedby="input-add-image"
                ></b-form-file>
                <b-form-invalid-feedback id="input-add-image">
                  الصورة مطلوبة
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="addRoomFunction()">
            إضافة غرفة
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="newRoomModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"
  import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
  import axios from 'axios';

  export default {
    name: 'Home',
    props:['isHome'],
    data() {
      return {
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        isLoading: false,
        privateRooms: [],
        newRoomModal: false,
        form: {
          name: null,
          subject: null,
          image: null
        },
      }
    },
    validations: {
      form: {
        name: {
          required
        },
        subject: {
          required
        },
        image: {
        },
      }
    },
    methods: {
      addRoomFunction() {
        this.addRoom()
      },
      addRoom() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.uploadImage();
      },
      resetForm() {
        this.form = {
          name: null,
          subject: null,
          image: null
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.newRoomModal = false;
      },
      uploadImage() {
        var formData = new FormData();
        formData.append("file", this.form.image);
        formData.append("folder_name", "rooms");
        axios.post(process.env.VUE_APP_API_URL + '/admin/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.form.image = response.data;
          this.submitAddRoom();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      submitAddRoom() {
        this.isLoading = true;
        this.form.approvement = "accept"
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم اضافة الغرفة بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.newRoomModal = false;
          this.isLoading = false;
          this.resetForm();
          this.loadPrivateRooms();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('post', '/teacher/create-room', true, this.form , successCallback, errorCallback);
      },
      openNewRoomModal() {
        this.newRoomModal = true;
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      goToRoom(room) {
        this.$router.push({
          name: "room",
          params: {
            roomID: room.id,
          }
        })
      },
      loadPrivateRooms() {
        this.isLoading = true;
        var successCallback = (response) => {
          if(this.isHome)
            this.privateRooms = response.data.data.slice(0, 4);
          else
            this.privateRooms = response.data.data;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/get-rooms?type=private', true, null , successCallback, errorCallback);
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.loadPrivateRooms();
    }
  }
</script>
<style scoped>
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline;
  }
  .div-hdr span {
    color: rgb(155, 155, 155);
    font-size: 14px;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .public-rooms-body{
    padding: 30px 10px;
  }
  .room-card{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    transition: 0.4s;
  }
  .room-card:hover{
    cursor: pointer;
    box-shadow: 0px 1px 15px #7650ff88;
    border-bottom: 5px solid #7550FF;
    margin: 5px 0px;
  }
  .room-card .card-body .card-title{ 
    font-size: 15px;
    font-weight: bold;
  }
  .card-img img{
    width: 100%;
    display: block;
    margin: auto;
  }
  .public-rooms-body .add-room{
    position: absolute;
    left: -11px;
    background-color: #ECECFA;
    padding: 3px 15px;
  }
  .public-rooms-body .card-text{
    color: rgb(155, 155, 155);
  }
  .private-rooms .live-room{
    display: inline;
    top: 3px;
    left: 10px;
    position: absolute;
  }
  .private-rooms .live-room .live-icon{
    color: red;
    font-size: 10px;
    padding-right: 6px;
  }
  .private-rooms .live-room .live-txt{
    color: rgb(92, 92, 92);
    font-size: 13px;
  }
  .private-rooms .card-text{
    color: rgb(155, 155, 155);
  }
  .private-rooms .card-footer-div {
    padding-top: 15px;
  }
  .private-rooms .card-footer-div .member-number{
    padding-right: 20px;
    font-size: 12px;
    color: rgb(155, 155, 155);
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
  } 
</style>
