<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="rooms-layout">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <div class="public-rooms">
            <div class="text-center text-danger my-2" v-show="isLoading">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="div-hdr room-hdr" v-show="!isLoading">
              <h5><b>{{roomData.name}}</b></h5>
              <b-button variant="danger" v-if="roomData.is_private == 1" size="sm" class="mr-2" @click="openDeleteRoomModal()"><i class="fa fa-trash"></i></b-button>
              <b-button variant="secondary" v-if="roomData.is_private == 1" size="sm" class="mr-2" @click="openEditRoomModal()"><i class="fa fa-edit"></i></b-button>
              <b-button variant="secondary" v-if="roomData.is_private == 1" size="sm" @click="openStudents()"><i class="fa fa-user"></i></b-button>
              <br>
              <p class="title">{{roomData.subject}}</p>
            </div>
            <div class="rooms-body" v-show="!isLoading">
              <div>
                <b-row>
                  <b-col lg="7" md="12" sm="12">    
                    <div class="classes">
                      <div class="div-hdr lessons-div">
                        <h5><b>الدروس</b></h5>
                        <b-button variant="primary" @click="openAddLessonModal()"><i class="fas fa-plus pl-2"></i>إضافة درس</b-button>
                      </div>
                      <div class="class-col" v-if="lessons.length == 0">
                        <div class="pt-5 no-content" style="text-align:center">
                          <img src="../assets/images/data-not-found.png">
                          <h4> لا يوجد دروس</h4>
                        </div>
                      </div>
                      <div class="class-col" v-else v-for="(lesson, index) in lessons" :key="index">
                        <b-row align-v="center">
                          <b-col lg="7" md="7" sm="12">
                            <b-media vertical-align="center" class="pr-2 class-col-click" @click="openLesson(lesson)">
                              <h5 class="mt-0 mb-2 lessonName"><b-badge variant="success" v-if="lesson.isNew"> جديد</b-badge> {{lesson.name}}</h5>
                              <p class="mb-0">{{lesson.description}}</p>
                            </b-media>
                          </b-col>
                          <b-col lg="5" md="5" sm="12">
                            <div class="action-buttons">
                              <b-button variant="primary" @click="openEditLessonModal(lesson)"><i class="far fa-edit"></i></b-button>
                              <b-button class="mr-2" variant="light" @click="openDeleteLessonModal(lesson.id)"><i class="far fa-trash-alt delete-icon"></i></b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="5" md="12" sm="12">
                    <div class="classes">
                      <div class="div-hdr lessons-div">
                        <h5><b>الملفات</b></h5>
                        <b-button variant="primary" @click="openAddFileModal()"><i class="fas fa-plus"></i> </b-button>
                      </div>
                      <div class="class-col files-col" v-if="files.length == 0">
                        <div class="pt-5 no-content" style="text-align:center">
                          <img src="../assets/images/data-not-found.png">
                          <h4> لا يوجد ملفات</h4>
                        </div>
                      </div>
                      <div class="class-col files-col" v-else v-for="(file, index) in files" :key="index">
                        <b-row align-v="center" :href="file.path">
                          <b-col lg="9" md="9" sm="12">
                            <b-link :href="file.path" target="_black">
                              <b-media vertical-align="center" class="pr-2 class-col-click" @click="openLesson(lesson)">
                                <h5 class="mt-0 mb-2 lessonName">{{file.name}}</h5>
                              </b-media>
                            </b-link>
                          </b-col>
                          <b-col lg="3" md="3" sm="12">
                            <div class="action-buttons">
                              <b-button variant="primary" size="sm" @click="openEditFileModal(file)"><i class="fa fa-edit"></i></b-button>
                              <b-button class="mr-2" variant="light"  size="sm" @click="openDeleteFile(file.id)"><i class="far fa-trash-alt delete-icon"></i></b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="addLessonModal" centered title="إضافة درس جديد" size="lg">
      <b-form ref="form" @submit.stop.prevent="addLesson">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-name" label="اسم الدرس" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-youtube_video_path" label="رابط فيديو الدرس" label-for="admin-add-youtube_video_path">
                <b-form-input
                  id="admin-add-youtube_video_path"
                  name="admin-add-youtube_video_path"
                  v-model="$v.form.youtube_video_path.$model"
                  :state="validateState('youtube_video_path')"
                  aria-describedby="input-add-youtube_video_path"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-youtube_video_path"  v-if='!$v.form.youtube_video_path.required'>
                  اسم فيديو الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-date" label="تاريخ الدرس" label-for="admin-add-date">
                <b-form-datepicker  
                  id="admin-add-date"
                  name="admin-add-date"
                  v-model="$v.form.date.$model"
                  :state="validateState('date')"
                  aria-describedby="input-add-date"
                ></b-form-datepicker  >
                <b-form-invalid-feedback id="input-add-date">
                  تاريخ الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-" label="موعد الدرس" label-for="admin-add-time">
               <b-form-timepicker  
                  id="admin-add-time"
                  name="admin-add-time"
                  v-model="$v.form.time.$model"
                  :state="validateState('time')"
                  aria-describedby="input-add-time"
                ></b-form-timepicker  >
                <b-form-invalid-feedback id="input-add-time">
                  موعد الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="group-admin-add-" label="وصف الدرس" label-for="admin-add-description">
                <b-form-textarea
                  id="admin-add-description"
                  name="admin-add-description"
                  v-model="$v.form.description.$model"
                  :state="validateState('description')"
                  rows="1"
                  aria-describedby="input-add-description"
                ></b-form-textarea>
                <b-form-invalid-feedback id="input-add-description">
                  وصف الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="addLessonFunction()">
            إضافة درس
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="addLessonModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="editLessonModal" centered title="تعديل درس" size="lg">
      <b-form ref="form" @submit.stop.prevent="addLesson">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-name" label="اسم الدرس" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.editForm.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-youtube_video_path" label="رابط فيديو الدرس" label-for="admin-add-youtube_video_path">
                <b-form-input
                  id="admin-add-youtube_video_path"
                  name="admin-add-youtube_video_path"
                  v-model="$v.editForm.youtube_video_path.$model"
                  :state="validateState('youtube_video_path')"
                  aria-describedby="input-add-youtube_video_path"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-youtube_video_path"  v-if='!$v.editForm.youtube_video_path.required'>
                  اسم فيديو الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-date" label="تاريخ الدرس" label-for="admin-add-date">
                <b-form-datepicker  
                  id="admin-add-date"
                  name="admin-add-date"
                  v-model="$v.editForm.date.$model"
                  :state="validateState('date')"
                  aria-describedby="input-add-date"
                ></b-form-datepicker  >
                <b-form-invalid-feedback id="input-add-date">
                  تاريخ الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-admin-add-" label="موعد الدرس" label-for="admin-add-time">
               <b-form-timepicker  
                  id="admin-add-time"
                  name="admin-add-time"
                  v-model="$v.editForm.time.$model"
                  :state="validateState('time')"
                  aria-describedby="input-add-time"
                ></b-form-timepicker  >
                <b-form-invalid-feedback id="input-add-time">
                  موعد الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="group-admin-add-" label="وصف الدرس" label-for="admin-add-description">
                <b-form-textarea
                  id="admin-add-description"
                  name="admin-add-description"
                  v-model="$v.editForm.description.$model"
                  :state="validateState('description')"
                  rows="1"
                  aria-describedby="input-add-description"
                ></b-form-textarea>
                <b-form-invalid-feedback id="input-add-description">
                  وصف الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="saveEditLessonFunction()">
            حفظ
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="editLessonModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="deleteLessonModal" centered title="حذف درس">
      <p class="my-4">هل تريد حذف الدرس ؟</p>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="secondary" @click="$bvModal.hide('deleteLessonModal')">
          الغاء
        </b-button>
        <b-button size="sm" variant="primary" @click="deleteLessonFunction()">
          حذف
        </b-button>
      </template>
    </b-modal>


    <!-- edit, deleteRoom -->
    <b-modal v-model="editRoomModal" centered title="تعديل غرقة خاصة" size="lg">
      <b-form ref="form" @submit.stop.prevent="editRoomFunction">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-name" label="اسم الغرفة" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.editRoomForm.name.$model"
                  :state="editRoomValidateState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الدرس مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-subject" label="المادة" label-for="admin-add-subject">
                <b-form-input
                  id="admin-add-subject"
                  name="admin-add-subject"
                  v-model="$v.editRoomForm.subject.$model"
                  :state="editRoomValidateState('subject')"
                  aria-describedby="input-add-subject"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-subject">
                  اسم المادة مطلوبة
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12">
              <b-form-group id="group-add-image" label="الصورة" label-for="add-image">
                <b-form-file
                  type="file"
                  id="add-image"
                  name="add-image"
                  v-model="$v.editRoomForm.image.$model"
                  :state="editRoomValidateState('image')"
                  aria-describedby="input-add-image"
                ></b-form-file>
                <b-form-invalid-feedback id="input-add-image">
                  الصورة مطلوبة
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="editRoom()">
            حفظ
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="editRoomModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="deleteRoomModal" centered title="حذف فصل">
      <p class="my-4">هل تريد حذف الفصل ؟</p>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="secondary" @click="deleteRoomModal = false">
          الغاء
        </b-button>
        <b-button size="sm" variant="primary" @click="deleteRoomFunction()">
          حذف
        </b-button>
      </template>
    </b-modal>


    <!-- create file -->
    <b-modal v-model="createFileModal" centered title="اضافة ملف" size="lg">
      <b-form ref="form" @submit.stop.prevent="addFormFunction">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-name" label="اسم الملف" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.addFileForm.name.$model"
                  :state="addFileValidationState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الملف مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-path" label="الملف" label-for="add-path">
                <b-form-file
                  type="file"
                  id="add-path"
                  name="add-path"
                  v-model="$v.addFileForm.path.$model"
                  :state="addFileValidationState('path')"
                  aria-describedby="input-add-path"
                ></b-form-file>
                <b-form-invalid-feedback id="input-add-path">
                  الملف مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="addFile()">
            حفظ
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="createFileModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="editFileModal" centered title="تعديل ملف" size="lg">
      <b-form ref="form" @submit.stop.prevent="editFileFunction">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-name" label="اسم الملف" label-for="admin-add-name">
                <b-form-input
                  id="admin-add-name"
                  name="admin-add-name"
                  v-model="$v.editFileForm.name.$model"
                  :state="editFileValidateState('name')"
                  aria-describedby="input-add-name"
                ></b-form-input>
                <b-form-invalid-feedback id="input-add-name">
                  اسم الملف مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12">
              <b-form-group id="group-add-path" label="الملف" label-for="add-path">
                <b-form-file
                  type="file"
                  id="add-path"
                  name="add-path"
                  v-model="$v.editFileForm.path.$model"
                  :state="editFileValidateState('path')"
                  aria-describedby="input-add-path"
                ></b-form-file>
                <b-form-invalid-feedback id="input-add-path">
                  الملف مطلوب
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="primary" type="submit" size="sm" class="float-left mr-1"  @click="editFile()">
            حفظ
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="editFileModal=false">
            الغاء
          </b-button>
        </div>
      </template>
    </b-modal>


    <b-modal v-model="deleteFileModal" centered title="حذف ملف">
      <p class="my-4">هل تريد حذف الملف ؟</p>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="secondary" @click="$bvModal.hide('deleteFileModal')">
          الغاء
        </b-button>
        <b-button size="sm" variant="primary" @click="deleteFileMethod()">
          حذف
        </b-button>
      </template>
    </b-modal>

 </div>
</template>

<script>
  import store from "../store/index.js"
  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
  import { sendRequest }  from "../utils/RequestHelper.js"
  import axios from 'axios';

  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        addLessonModal: false,
        editLessonModal: false,
        deleteLessonModal: false,
        editRoomModal: false,
        deleteRoomModal: false,
        createFileModal: false,
        editFileModal: false,
        deleteFileModal: false,
        seletedLesson: null,
        selectedFile: null,
        form: {
          name: null,
          youtube_video_path: null,
          date: null,
          time: null,
          description: null
        },
        editForm: {
          name: null,
          youtube_video_path: null,
          date: null,
          time: null,
          description: null
        },
        editRoomForm: {
          name: null,
          subject: null,
          image: null
        },
        addFileForm: {
          name: null,
          path: null
        },
        editFileForm: {
          name: null,
          path: null
        },
        roomData: {},
        lessons: [],
        files: []
      }
    },
    validations: {
      form: {
        name: {
          required
        },
        youtube_video_path: {
        },
        date: {
          required
        },
        time: {
          required
        },
        description: {
          required
        }
      },
      editForm: {
        name: {
          required
        },
        youtube_video_path: {
        },
        date: {
          required
        },
        time: {
          required
        },
        description: {
          required
        }
      },
      editRoomForm: {
        name: {
          required
        },
        subject: {
          required
        },
        image: {
        },
      },
      addFileForm: {
        name: {
          required
        },
        path: {
          required
        },
      },
      editFileForm: {
        name: {
          required
        },
        path: {
        },
      }
    },
    methods: {
      openStudents() {
        this.$router.push({
          name: "studentsOfRoom",
          params: {
            roomID: this.$route.params.roomID
          }
        })
      },
      // updateRoom
      openEditRoomModal() {
        this.editRoomForm.name = this.roomData.name;
        this.editRoomForm.subject = this.roomData.subject;
        this.editRoomForm.image = null;
        this.editRoomModal = true;
      },
      editRoom() {
        this.editRoomFunction();
      },
      editRoomFunction() {
        this.$v.editRoomForm.$touch();
        if (this.$v.editRoomForm.$anyError) {
          return;
        }
        this.uploadRoomImage();
      },
      uploadRoomImage() {
        if(this.editRoomForm.image) {
          var formData = new FormData();
          formData.append("file", this.editRoomForm.image);
          formData.append("folder_name", "rooms");
          axios.post(process.env.VUE_APP_API_URL + '/teacher/upload-file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.editRoomForm.image = response.data;
            this.submitEditRoom();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          });
        }
        else{
          delete this.editRoomForm.image;
          this.submitEditRoom();
        }
      },
      submitEditRoom() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم تعديل الغرفة بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.newRoomModal = false;
          this.isLoading = false;
          this.resetEditRoomForm();
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/update-room/' + this.$route.params.roomID, true, this.editRoomForm , successCallback, errorCallback);
      },
      resetEditRoomForm() {
        this.editRoomForm = {
          name: null,
          subject: null,
          image: null
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.editRoomModal = false;
      },
      editRoomValidateState(name) {
        const { $dirty, $error } = this.$v.editRoomForm[name];
        return $dirty ? !$error : null;
      },


      //files
      openAddFileModal() {
        this.createFileModal = true;
      },
      addFile() {
        this.addFormFunction();
      },
      addFormFunction() {
        this.$v.addFileForm.$touch();
        if (this.$v.addFileForm.$anyError) {
          return;
        }
        this.uploadFile();
      },
      uploadFile() {
        var formData = new FormData();
        formData.append("file", this.addFileForm.path);
        formData.append("folder_name", "room_files");
        axios.post(process.env.VUE_APP_API_URL + '/teacher/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          this.addFileForm.path = response.data;
          this.submitAddFile();
        })
        .catch((error) => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        });
      },
      submitAddFile() {
        this.isLoading = true;
        this.addFileForm.room_id = this.$route.params.roomID;
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم اضافة الملف بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.newRoomModal = false;
          this.isLoading = false;
          this.resetAddFormData();
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('post', '/teacher/upload-file-lesson', true, this.addFileForm , successCallback, errorCallback);
      },
      resetAddFormData() {
         this.addFileForm = {
          name: null,
          path: null
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.createFileModal = false;
      },
      addFileValidationState(name) {
        const { $dirty, $error } = this.$v.addFileForm[name];
        return $dirty ? !$error : null;
      },
      openDeleteFile(id) {
        this.selectedFile = id;
        this.deleteFileModal = true;
      },
      deleteFileMethod() {
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم حذف الملف بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.deleteFileModal = false;
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('delete', '/teacher/delete-file-lesson/' + this.selectedFile, true, null , successCallback, errorCallback);
      },

      // editFile




      openEditFileModal(file) {
        this.editFileForm.name = file.name;
        this.editFileForm.path = null;
        this.selectedFile = file.id;
        this.editFileModal = true;
      },
      editFile() {
        this.editFileFunction();
      },
      editFileFunction() {
        this.$v.editFileForm.$touch();
        if (this.$v.editFileForm.$anyError) {
          return;
        }
        this.editUploadFile();
      },
      editUploadFile() {
        if(this.editFileForm.path){
          var formData = new FormData();
          formData.append("file", this.editFileForm.path);
          formData.append("folder_name", "room_files");
          axios.post(process.env.VUE_APP_API_URL + '/teacher/upload-file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            this.editFileForm.path = response.data;
            this.submitEditFile();
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          });
        }
        else{
          delete this.editFileForm.path;
          this.submitEditFile();
        }
      },
      submitEditFile() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم تعديل الملف بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.newRoomModal = false;
          this.isLoading = false;
          this.resetEditFileForm();
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/update-file-lesson/' + this.selectedFile, true, this.editFileForm , successCallback, errorCallback);
      },
      resetEditFileForm() {
        this.editFileForm = {
          name: null,
          paht: null,
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.editFileModal = false;
      },
      editFileValidateState(name) {
        const { $dirty, $error } = this.$v.editFileForm[name];
        return $dirty ? !$error : null;
      },





      // deleteRoom
      openDeleteRoomModal() {
        this.deleteRoomModal = true;
      },
      deleteRoomFunction() {
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم حذف الغرفة بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.$router.push({
            name: "privateRooms"
          })
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/update-room/' + this.$route.params.roomID, true, {approvement: "blocked"} , successCallback, errorCallback);
      },
      openDeleteLessonModal(id) {
        this.seletedLesson = id;
        this.deleteLessonModal = true;
      },
      deleteLessonFunction() {
        var successCallback = (response) => {
          this.alertType = "success"
          this.alertText = "تم حذف الدرس بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.deleteLessonModal = false;
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('delete', '/teacher/lives/' + this.seletedLesson, true, null , successCallback, errorCallback);
      },
      openEditLessonModal(lesson) {
        this.editForm.id = lesson.id
        this.editForm.name = lesson.name
        this.editForm.youtube_video_path = lesson.youtube_video_path
        this.editForm.description = lesson.description
        this.editForm.date = lesson.appointment.slice(0, 10);
        this.editForm.time = lesson.appointment.slice(10, 18);
        this.editLessonModal = true;
      },
      openLesson(lesson) {
        this.$router.push({
          name: "lesson",
          params: {
            lessonID: lesson.id,
          }
        })
      },
      saveEditLessonFunction() {
        this.$v.editForm.$touch();
        if (this.$v.editForm.$anyError) {
          return;
        }
        this.editFormFunction();
      },
      editFormFunction() {
        this.addLessonIsLoading = true;
        this.editForm.appointment = this.editForm.date + this.editForm.time;
        this.editForm.room_id = this.$route.params.roomID;
        delete this.editForm.date;
        delete this.editForm.time;
        delete this.editForm.roomID;
        var successCallback = (response) => {
          this.addLessonIsLoading = false;
          this.alertType = "success"
          this.alertText = "تم تعديل الدرس بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.editLessonModal = false;
          this.resetEditForm();
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/lives/' + this.editForm.id, true, this.editForm , successCallback, errorCallback);
      },
      addLessonFunction() {
        this.addLesson();
      },
      addLesson() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.addData();
      },
      addData() {
        this.addLessonIsLoading = true;
        this.form.appointment = this.form.date + this.form.time;
        this.form.room_id = this.$route.params.roomID;
        delete this.form.date;
        delete this.form.time;
        var successCallback = (response) => {
          this.addLessonIsLoading = false;
          this.alertType = "success"
          this.alertText = "تم اضافة الدرس بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.addLessonModal = false;
          this.resetForm();
          this.getRoomData();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('post', '/teacher/lives', true, this.form , successCallback, errorCallback);
      },
      resetForm() {
        this.form = {
          name: null,
          youtube_video_path: null,
          date: null,
          time: null,
          description: null,
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.addLessonModal = false;
      },
      resetEditForm() {
        this.editForm = {
          name: null,
          youtube_video_path: null,
          date: null,
          time: null,
          description: null,
        };
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.editLessonModal = false;
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      validateEditState(name) {
        const { $dirty, $error } = this.$v.editForm[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      openAddLessonModal() {
        this.addLessonModal = true;
      },
      getRoomData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.roomData = response.data.data;
          var newLives = this.roomData.new_lives;
          newLives.forEach(live => {
            live.isNew = true;
          });
          var oldLives = this.roomData.lives;
          oldLives.forEach(live => {
            live.isNew = false;
          });
          this.lessons = newLives.concat(oldLives);
          this.files = this.roomData.files;
          this.isLoading = false
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.$router.push({
            name: "home"
          })
        };
        sendRequest('get', '/teacher/show-room/' + this.$route.params.roomID, true, null , successCallback, errorCallback);
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.getRoomData();
    }
  }
</script>
<style scoped>
  .rooms-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline !important;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .div-hdr .title{
    color: rgb(155, 155, 155);
    font-size: 15px;
    padding-top: 10px;
  }
  .classes{
    margin-top: 30px;
  }
  .video-details{
    font-weight: bold;
    font-size: 15px;
    width: 70%;
    margin-top: 20px;
  }
  .lessons-div{
    margin-bottom: 40px;
    padding: 0px;
  }
  .class-col{
    margin: 15px 0px;
    padding: 20px 10px 20px 30px;
    border-radius: 5px;
    background-color: #fff;
  }
  .files-col{
    padding-left: 10px;
  }
  .class-col .image{
    margin-left: 15px;
    width: 60px !important;
    height: 60px !important;
  }
  .class-col h5{
    font-size: 16px;
    font-weight: 600;
    padding-top: 7px;
  }
  .class-col p{
    font-size: 14px;
    color: rgb(155, 155, 155);
  }
  .class-col .action-buttons{
    float: left;
  }
  .delete-icon{
    color: #5e3fd0;
  }
  .class-col-click{
     transition: 0.4s;
  }
  .room-hdr{
    padding: 0px;
  }
  .class-col-click:hover {
    cursor: pointer;
  }
  .class-col-click:hover .lessonName {
    text-decoration: underline;
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  @media (max-width: 767px) {
    .room-col{
      padding-left: 20px;
    }
    .room-col .action-buttons{
      margin-top: 20px;
    }
    .class-col{
      padding-left: 20px;
    }
    .class-col .action-buttons{
      margin-top: 20px;
    }
    .video-details{
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .rooms-layout{
      margin-top: 50px;
    }
  } 
</style>
