<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="requests-layout">
      <div class="public-rooms">
        <div class="div-hdr" v-show="!isLoading">
          <h5><b> طلبة الغرفة  {{this.roomData.name}}</b></h5>
        </div>
        <div class="text-center text-danger my-2" v-show="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <div class="requests-body" v-show="!isLoading">
          <b-row>
            <b-col lg="10" md="12" sm="12" v-if="requests.length == 0">
              <div class="pt-5 no-content" style="text-align:center">
                <img src="../assets/images/data-not-found.png">
                <h4> لا يوجد طلبه</h4>
              </div>
            </b-col>
            <b-col lg="10" md="12" sm="12" v-for="(request, index) in requests" :key="index">
              <div class="request-col">
                <b-row align-v="center">
                  <b-col lg="7" md="7" sm="12">
                    <b-media vertical-align="center">
                      <template v-slot:aside>
                        <b-avatar class="image" :src="request.student.image"></b-avatar>
                      </template>
                      <h5 class="mt-3">{{request.student.full_name}}</h5>
                    </b-media>
                  </b-col>
                  <b-col lg="5" md="5" sm="12">
                    <div class="action-buttons">
                      <b-button class="mr-2" variant="light" @click="refuseRequestfunction(request.id)"><i class="far fa-trash-alt delete-icon"></i></b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>

    <b-modal v-model="refuseRequest" centered title="رفض طلب الانضمام">
      <p class="my-4">هل تريد رفض انضمام الطالب للغرفة؟</p>
      <b-textarea v-model="blockReason" placeholder="سبب الرفض"></b-textarea>
      <template v-slot:modal-footer>
        <b-button size="sm" variant="secondary" @click="$bvModal.hide('refuseRequest')">
          الغاء
        </b-button>
        <b-button size="sm" variant="primary" @click="actionRequest('blocked')">
          رفض
        </b-button>
      </template>
    </b-modal>
 </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"
  
  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        refuseRequest: false,
        seletedRequestID: null,
        blockReason: null,
        roomData: {},
        requests: []
      }
    },
    methods: {
      refuseRequestfunction(id) {
        this.blockReason = null;
        this.seletedRequestID = id;
        this.refuseRequest = true;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      loadStudents() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.requests = response.data.data;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/show-teacher-students?roomId=' + this.$route.params.roomID, true, null , successCallback, errorCallback);
      },
      loadRoomData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.roomData = response.data.data;
          this.loadStudents();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/show-room/' + this.$route.params.roomID, true, null , successCallback, errorCallback);
      },
      actionRequest(action) {
        this.isLoading = true;
        var message = null;
        var successCallback = () => {
          this.alertType = "success"
          this.alertText = "تم رفض الطالب بنجاح"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.refuseRequest = false;
          this.loadStudents();
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('put', '/teacher/change-join-request-status/' + this.seletedRequestID + '/blocked?block_reason=' + this.blockReason , true, {} , successCallback, errorCallback);
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.loadRoomData();
    }
  }
</script>
<style scoped>
  .requests-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline;
  }
  .requests-body{
    padding: 30px 10px;
  }
  .request-col{
    margin: 5px 0px;
    padding: 20px 10px 20px 30px;
    border-radius: 5px;
    background-color: #fff;
  }
  .request-col .image{
    margin-left: 15px;
    width: 60px !important;
    height: 60px !important;
  }
  .request-col h5{
    font-size: 16px;
    font-weight: 600;
    padding-top: 7px;
  }
  .request-col p{
    font-size: 14px;
    color: rgb(155, 155, 155);
  }
  .request-col .action-buttons{
    float: left;
  }
  .delete-icon{
    color: #5e3fd0;
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  @media (max-width: 767px) {
    .request-col{
      padding-left: 20px;
    }
    .request-col .action-buttons{
      margin-top: 20px;
    }
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .requests-layout{
      margin-top: 50px;
    }
  } 
</style>
