<template>
    
      <div class="private-rooms">
        <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
          {{alertText}}
        </b-alert>
        <div class="div-hdr">
          <h5><b>الغرف العامة</b></h5>
          <b-link @click="goToPublicRooms()" v-if="isHome"><span>عرض الكل</span></b-link>
        </div>
        <div class="text-center text-danger my-2" v-show="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <div class="public-rooms-body" v-show="!isLoading">
          <b-row>
            <b-col lg="12" md="12" sm="12" v-if="publicRooms.length == 0">
              <div class="pt-5 no-content" style="text-align:center">
                <img src="../assets/images/data-not-found.png">
                <h4>لا يوجد غرف عامة</h4>
              </div>
            </b-col>
            <b-col lg="3" md="6" sm="6" v-else v-for="(room, index) in publicRooms" :key="index">
              <div>
                <b-card no-body class="overflow-hidden room-card" :class="{'room-card-hover' : room.is_registered == 1}" @click="(room.is_registered == 1)?goToRoom(room) : null">
                  <div class="live-room" v-if="room.isLive">
                    <span><span class="live-txt">Live</span><i class="fas fa-circle live-icon"></i></span>
                  </div>
                  <div class="card-img">
                    <img :src="room.image" alt="Image" class="rounded-0">
                  </div>
                  <b-card-body :title="room.name">
                    <b-card-text>
                      {{room.subject}}
                    </b-card-text>
                    <b-button class="add-room" variant="primary" @click="joinRoom(room.id)" v-if="room.is_registered == 0" title="Join to Room">
                      <div>
                        <i class="fas fa-plus "></i>
                      </div>
                    </b-button>
                  </b-card-body>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"
  
  export default {
    name: 'Home',
    props:['isHome'],
    data() {
      return {
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        isLoading: false,
        publicRooms: [],
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      goToRoom(room) {
        this.$router.push({
          name: "room",
          params: {
            roomID: room.id,
            roomData: room
          }
        })
      },
      loadPublicRooms() {
        this.isLoading = true;
        var successCallback = (response) => {
          if(this.isHome)
            this.publicRooms = response.data.data.slice(0, 4);
          else
            this.publicRooms = response.data.data;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/show-public-rooms', true, null , successCallback, errorCallback);
      },
      goToPublicRooms() {
        this.$router.push({
          name: "publicRooms"
        })
      },
      joinRoom(id) {
         this.isLoading = true;
        var successCallback = (response) => {
          
          this.alertType = "success"
          this.alertText = "تمت اضافتك للغرفة بنجاح"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.loadPublicRooms();
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/join-room/' + id, true, null , successCallback, errorCallback);
      },
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.loadPublicRooms();
    }
  }
</script>
<style scoped>
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline;
  }
  .div-hdr span {
    color: rgb(155, 155, 155);
    font-size: 14px;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .public-rooms-body{
    padding: 30px 10px;
  }
  .room-card{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
    transition: 0.4s;
  }
  .room-card-hover{
    padding-bottom: 26px;
  }
  .room-card-hover:hover{
    cursor: pointer;
    box-shadow: 0px 1px 15px #7650ff88;
    border-bottom: 5px solid #7550FF;
    margin: 5px 0px;
  }
  .room-card .card-body .card-title{ 
    font-size: 15px;
    font-weight: bold;
  }
  .card-img img{
    width: 100%;
    display: block;
    margin: auto;
  }
  .public-rooms-body .add-room{
    position: absolute;
    left: -11px;
    background-color: #7550ff !important;
    padding: 3px 15px;
  }
  .public-rooms-body .card-text{
    color: rgb(155, 155, 155);
  }
  .private-rooms .live-room{
    display: inline;
    top: 3px;
    left: 10px;
    position: absolute;
  }
  .private-rooms .live-room .live-icon{
    color: red;
    font-size: 10px;
    padding-right: 6px;
  }
  .private-rooms .live-room .live-txt{
    color: rgb(92, 92, 92);
    font-size: 13px;
  }
  .private-rooms .card-text{
    color: rgb(155, 155, 155);
  }
  .private-rooms .card-footer-div {
    padding-top: 15px;
  }
  .private-rooms .card-footer-div .member-number{
    padding-right: 20px;
    font-size: 12px;
    color: rgb(155, 155, 155);
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  .public-rooms-body .add-room{
    position: absolute;
    left: 0px;
    background-color: #ECECFA;
    padding: 3px 15px;
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
  } 
</style>
