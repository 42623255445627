<template>
  <div v-if="!['login', 'landingPage'].includes($route.name)">
    <div class="header">
      <div class="logoutBtn-div">
        <b-form-input  class="search-bar" type="search" v-model="search" placeholder="بحث" @change="search? searchMehtod(search): ''">
        </b-form-input>
        <b-dropdown  class="hdr-nav-notification-btn" variant="light" no-caret>
          <template v-slot:button-content >
            <sup v-if="false"><i class="fas fa-circle has-notification"></i></sup>
            <i class="far fa-bell"></i>
          </template>
          <ul class="list-unstyled notification-ul" >
            <b-media tag="li">
            </b-media>
          </ul>
        </b-dropdown>
        <b-dropdown size="sm" class="logoutBtn" variant="light">
          <template v-slot:button-content>
            <b-avatar :src="userImage"></b-avatar><span class="mr-1 ml-1">{{username}}</span>
          </template>
          <b-dropdown-item v-b-modal.modal-logout>تسجيل الخروج</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <a href="#" class="nav-trigger" @click="navToogle">
      <span></span>
    </a>
    <div class="side-nav" :class="{ visible: isVisible }" v-if="!['NotFound'].includes($route.name)">
      <div class="userCard">
        <b-avatar :src="userImage" size="4rem"></b-avatar>
        <br><br>
        <span class="username">{{username}}</span>
        <br>
      </div>
      <nav>
        <ul>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/home">
              <div class="nav-item-list">
                <i class="fa fa-home"></i>
                <span class="hideInSmall">الرئيسية</span>
              </div>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/requests">
              <div class="nav-item-list">
                <i class="fas fa-user-plus"></i>
                <span class="hideInSmall">الطلبات</span>
              </div>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/private-rooms">
              <div class="nav-item-list">
                <i class="fas fa-chalkboard"></i>
                <span class="hideInSmall">الغرف الخاصة</span>
              </div>
            </router-link>
          </li>
          <li @click="isVisible = false">
            <router-link class="nav-link" to="/settings">
              <div class="nav-item-list">
                <i class="fas fa-cog"></i>
                <span class="hideInSmall">الإعدادات</span>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <b-modal id="modal-logout" title="تسجيل الخروج" centered size="lg">
      <p class="my-4">هل تريد تسجيل الخروج?</p>
      <template v-slot:modal-footer>
      <b-button size="sm" variant="secondary" @click="$bvModal.hide('modal-logout')">
        الغاء
      </b-button>
      <b-button size="sm" variant="primary" @click="logout()">
        الخروج
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import store from "../../store/index.js"
import { sendRequest }  from "../../utils/RequestHelper.js"

export default {
  data() {
    return {
      isVisible: false,
      username: null,
      userImage: null,
      requestIsLoading: false,
      search: null,
      searchResults: []
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      this.username = this.$store.getters.username;
      this.userImage = this.$store.getters.getImage;
    }
  },
  methods: {
    searchMehtod(search) {
      this.$router.push({
        name: "search",
        params: {
          search
        }
      })
    },
    navToogle() {
      this.isVisible = !this.isVisible;
    },
    logout() {
      var successCallback = () => {
        store.dispatch("LOGOUT");
        this.$router.push({
          name: "landingPage"
        })
      };
      var errorCallback = () => {
      };
      sendRequest('post', '/admin/logout', true, {}, successCallback, errorCallback);
    }
  },
  created() {
    this.username = this.$store.getters.username;
    this.userImage = this.$store.getters.getImage;
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #d5dae5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
  background-color: #fff;
}
.nav-link{
  padding: 0px;
}
/* logo in header(mobile version) and side-nav (tablet & desktop) */
.logo {
  height: 50px;
  padding: 10px;
  font-weight: 700;
}
.header .logo {
  color: #233245;
}
.side-nav .logo {
  background-color: #233245;
  color: #fff;
}
.header .logo {
  float: right;
}
.header .logo {
  height: 55px;
  z-index: 1;
  padding: 10px;
  font-weight: 700;
  color: #233245;
  margin-right: 40px;
}
.logo i {
  font-size: 22px;
}
.logo span {
  margin-top: 2px;
  margin-right: 5px;
  text-transform: uppercase;
}
.logoImg{
  width: 20px;
}
.nav-trigger {
  position: fixed;
  top: 7px;
  right: 27px;
  float: right;
  width: 20px;
  height: 50px;
  right: 30px;
  display: block;
  z-index: 10;
}
.nav-trigger span,
.nav-trigger span:before,
span:after {
  width: 20px;
  height: 2px;
  background-color: black;
  position: absolute;
}
.nav-trigger span {
  top: 50%;
}
.nav-trigger span:before,
.nav-trigger span:after {
  content: "";
}
.nav-trigger span:before {
  top: -6px;
}
.nav-trigger span:after {
  top: 6px;
}
/* side navigation styles */
.side-nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 1;
  display: grid;
}
.side-nav.visible {
  display: block;
  position: fixed;
}
.side-nav ul {
  margin: 0;
  padding: 0;
}
.side-nav ul li {
  position: relative;
}
.nav-item-list{
  padding: 10px 15px;
}
.nav-item-list i{
  font-size: 20px !important;
}
.router-link-exact-active .nav-item-list{
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #F8F8FC;
}
.router-link-exact-active i{
  font-size: 15px !important;
  background-color: #7550FF;
  color: white !important;
  padding: 7px;
  border-radius: 5px;
}
.side-nav ul li a{
  padding: 7px 16px;
}
.side-nav ul li.active:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.router-link-exact-active:has(li){
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.side-nav ul li a {
  color: rgb(0, 0, 0);
  display: block;
  text-decoration: none;
}
.side-nav ul li i {
  color: rgb(0, 0, 0);
  min-width: 20px;
  text-align: center;
}
.side-nav ul li span:nth-child(2) {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

/* main content styles */
.main-content {
  padding: 40px;
  margin-top: 0;
  padding: 0;
  padding-top: 50px;
  height: 100%;
}
.main-content .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #b8bec9;
  padding: 10px 20px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
}
.userCard{
  padding: 60px 20px;
  text-align: center;
}
.userCard .username{
  color: black;
}
.userCard .user-job{
  color: #9897AD;
}
.nav-trigger{
  color: black;
  z-index: 10;
}
nav {
  padding-top: 30px;  
}
/* set element styles to fit tablet and higher(desktop) */
@media screen and (min-width: 600px) {
  
  .header {
    background-color: #ffffff;
    z-index: 1;
    position: fixed;
  }
  .header .logo {
    display: none;
  }
  .nav-trigger {
    display: none;
  }
  .side-nav{
    display: none;
  }
  .nav-trigger span,
  .nav-trigger span:before,
  span:after {
    background-color: #fff;
  }
  .side-nav {
    display: block;
    width: 70px;
    z-index: 2;
  }
  .side-nav ul li span:nth-child(2) {
    display: none;
  }
  .side-nav .logo i {
    padding-right: 12px;
  }
  .side-nav .logo span {
    display: none;
  }
  .side-nav ul li i {
    font-size: 26px;
  }
  .side-nav ul li a {
    padding: 0px;
    text-align: center;
  }
  .main-content {
    margin-right: 70px;
  }   
  .btn-group>.btn{
    background-color: transparent !important;
  }
  .userCard{
    display: none;
  }
}
/* set element styles for desktop */
@media screen and (min-width: 1100px) {
  
  .userCard{
    display: block;
  }
  .side-nav {
    width: 15%;
  }
  .side-nav ul li span:nth-child(2) {
    display: inline-block;
  }
  .side-nav ul li i {
    font-size: 16px;
  }
  .side-nav ul li a {
    text-align: right;
    padding:  7px 16px;
  }
  .side-nav .logo i {
    padding-right: 0;
  }
  .side-nav .logo span {
    display: inline-block;
  }
  .main-content {
    margin-right: 200px;
  }
  .dropdown-toggle{
    color: white !important;
  }
  .btn-group>.btn{
    background-color: transparent !important;
  }
}
/* main box container */
.main {
  display: flex;
  flex-flow: row wrap;
}
.widget {
  flex-basis: 300px;
  flex-grow: 10;
  height: 300px;
  margin: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}
.widget .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #dfe4ec;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #617085;
  font-weight: 600;
}
.ad {
  width: 350px;
  height: 300px;
  border: 1px solid #ddd;
}
.btn-group>.btn{
  background-color: transparent !important;
}
.logoutBtn{
  float: left;
  color:red;
  margin-left: 10px;
}

header {
  display: flex;
  justify-content: flex-end;
}
.logoutBtn-div{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  background-color: white;
    padding-bottom: 9px;
}
.logoutBtn-div div{
  padding-left: 30px;
  margin-top: 0px;
  float: left;
}
.logoutBtn-div .notification i{
  font-size: 20px;
}
.header .logoutBtn-div div .btn-group>.btn:first-child {
  background-color: green !important;
}
.header .dropdown-menu{
  padding: 0px !important;
}
.btn-group>.btn:first-child{
  background-color: #fff;
}
.search-bar{
  width: 40%;
  margin-left: 40px;
}
.has-notification{
  color: #7A40F2;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 8px !important;
}
.notification-ul {
    width: 500px;
}
.notification-ul .media{
  padding: 10px 0px;
  border-bottom: 1px solid #dedede;
  transition: 0.3s;
}
.notification-ul .media:hover{
  background-color: #f1f1f1;
  cursor: pointer;
}
.notification-ul h5{
  font-size: 14px;
  color: #4D565C;
  font-weight: bold;
}
.notification-ul p{
  font-size: 12px;
  color: rgb(155, 155, 155);
}
.notification-ul .all-notification{
  text-align: center;
  color: #7550ff;
  font-weight: bold;
  padding-bottom: 0.25rem !important;
}
.notification-ul .notification-time span{
  padding-top: 5px;
  padding-left: 5px;
}
@media (max-width: 800px) {
  .search-bar{
    display: none;
  }
}
@media (max-width: 600px) {
  .logoutBtn-div{
    background-color: white;
    padding-bottom: 9px;
  }
  .logoutBtn-div .btn-group{
    padding-left: 10px;
  }
  .side-nav{
    display: none;
  }
  nav {
    padding-top: 70px;
  }
  .userCard{
    display: none;
  }
}
.hdr-nav-notification-btn :first-child,
.hdr-nav-notification-btn :first-child:focus, 
.logoutBtn :first-child,
.logoutBtn :first-child:focus {
  background-color: transparent !important;
  border: unset;
  box-shadow: unset;
}
.nav-link{
  transition: 0.5s;
}
.nav-link:hover{
  border-right: 5px solid #7550FF;
}
</style>