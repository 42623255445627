const actions  = {
  SET_ACCOUNT_TYPE({commit}, account_type) {
    commit("SET_ACCOUNT_TYPE", account_type);
  },
  SET_USERNAME({commit}, user_name) {
    commit("SET_USERNAME", user_name);
  },
  SET_IMAGE({commit}, image) {
    commit("SET_IMAGE", image);
  },
  SET_USER_ID({commit}, userID) {
    commit("SET_USER_ID", userID);
  },
  LOGOUT({commit}) {
    commit("LOGOUT");
  }
}
export default actions 
