<template>
  <div id="app">
    <nav-bar :accountType='accountType'></nav-bar>
    <router-view :class="{'pageContent': isLoginPage}"></router-view>
  </div>
</template>

<script>
import navBar from "./components/navBar/nav.vue";
import store from "./store/index.js"

export default {
  data() {
    return {
      form: "",
      accountType: null,
      isLoginPage: true
    };
  },
  watch: {
    '$route' (to, from) {
      this.isLoginPage = true;
      if(this.$route.name == 'login'){
        this.isLoginPage = false;
      }
      else{
        this.isLoginPage = true;
      }
    }
  },
  components:{
    navBar
  },
  created() {
    this.isLoginPage = true;
    if(this.$route.name == 'login' || this.$route.name == 'landingPage'){
      this.isLoginPage = false;
    }
    else{
      this.isLoginPage = true;
    }
  }
};
</script>
<style scoped lang="scss">

  @font-face {
    font-family: "cairo-bold";
    src: url("../src/assets/fonts/Cairo-Bold.ttf");
  }
  @font-face {
    font-family: "cairo-regular";
    src: url("../src/assets/fonts/Cairo-Regular.ttf");
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "cairo-regular" !important;
    background-color: #d5dae5;
    -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .pageContent{
    width: 85%;
    float: left;
    padding: 30px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 35px;
  }
  button .btn-primary{
    color: #fff;
    background-color: #7550ff !important;
    border-color: #7550ff !important;
  }
  @media (max-width: 1100px) {
    .pageContent{
      width: 93%;
      float: left;
      padding: 30px;
      margin-top: 30px;
    }
  }
  @media (max-width: 800px) {
    .pageContent{
      width: 91%;
      float: left;
      padding: 30px;
      margin-top: 30px;
    }
  }
  @media (max-width: 600px) {
    .pageContent{
      width: 100%;
      float: left;
      padding: 10px;
      margin-top: 10px;
    }
  }
</style>
