<template>
  <div class="notFound">
    <h1>404</h1>
    <p>Page Not Found</p>
    <b-button variant="secondary" @click="goToHome()">العودة للصفحة الرئيسية</b-button>
  </div>
</template>
<script>
  export default {
    methods: {
      goToHome() {
        this.$router.push({
          name: "home"
        })
      }
    } 
  }
</script>
<style scoped>
.notFound{
  width: 100% !important;
  padding-top: 100px;
  text-align: center;
  color: #233245;
}
.notFound h1{
  font-size: 120px;
  font-weight: bold;
}
.notFound p{
  font-size: 25px;
  font-weight: bold;
}
</style>
