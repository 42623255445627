<template>
  <div class="landing-page-container">
    <div class="">
      <div class="panel panel-default landing-page">
        <div class="landing-page-div">
          <!-- navBar -->
          <div class="navBar">
            <b-navbar toggleable="lg" variant="faded" type="light">
              <b-navbar-brand href="/">
                <img class="nav-logo" src="../../src/assets/images/logo-dark.png" alt="EGSLive">
              </b-navbar-brand>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="nav-items">
                  <b-nav-item href="#EGsLive-features">مميزات المنصة</b-nav-item>
                  <b-nav-item href="#about-us">نبذه عنا</b-nav-item>
                  <b-nav-item href="#how-it-work">كيف نعمل</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="mr-auto">
                  <b-button size="sm" @click="goToLogin()" class="nav-btn nav-btn-1" variant="primary" >تسجيل الدخول</b-button>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>

          <!-- advantages -->
          <section>
            <div class="container intro-div" >
              <b-row align-v="center">
                <b-col lg="6" md="12" sm="12">
                  <div class="intro-col">
                    <h2 class="intro-header"><b>منصة مصرية مجانية للتعلم عن بعد</b></h2>
                    <p class="pt-4 intro-text">يُقدّمها نخبة متميزة من المعلمين والمعلمات
                      لتسهّل على الطلبة مواصلة تعلّمهم، ومتابعة موادهم الدراسية</p>
                      <div class="intro-btns-dib">
                        <b-link href="https://play.google.com/store/apps/details?id=com.app.egslive&hl=en" target="_blank">
                          <b-button size="sm" class="intro-btn intro-btn-1" variant="light" >
                            <img class="pl-2 pt-4" src="../../src/assets/images/landing-page/google-play.png">
                            <span class="pr-3"><b>حمل الآن</b></span>
                            <br>
                            <span class="pr-5"><b>Google Play</b></span>
                          </b-button>
                        </b-link>
                        <b-link v-if="false">
                          <b-button size="sm" class="intro-btn intro-btn-2" variant="light">
                            <img class="pl-2 pt-4" src="../../src/assets/images/landing-page/apple-logo.png">
                            <span class="pr-3"><b>حمل الآن</b></span>
                            <br>
                            <span class="pr-5"><b>App Store</b></span>
                          </b-button>
                        </b-link>
                      </div>
                  </div>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <div class="intro-img">
                    <img src="../../src/assets/images/landing-page/intro.png">
                  </div>
                </b-col>
              </b-row>
            </div>
          </section>

          <section class="adv-section" id="EGsLive-features">
            <div class="container adv-div" >
              <div class="adv-div-hdr">
                <h3>
                  مميزات المنصة
                </h3>
              </div>
              <b-row align-v="center">
                <b-col lg="4" md="12" sm="12">
                  <div class="adv-col-div">
                    <img src="../../src/assets/images/landing-page/adv-1.png">
                    <br>
                    <div class="adv-col-hdr">
                      <h5><b>كورسات متنوعة</b></h5>
                      <!-- <p>هذا النص هو مثال لنص يمكن أن يستبدل
                        في نفس المساحة، لقد تم توليد هذا النص </p> -->
                    </div>
                  </div>
                </b-col>
                <b-col lg="4" md="12" sm="12">
                  <div class="adv-col-div">
                    <img src="../../src/assets/images/landing-page/adv-2.png">
                    <br>
                    <div class="adv-col-hdr">
                      <h5><b>عروض خاصة</b></h5>
                      <!-- <p>هذا النص هو مثال لنص يمكن أن يستبدل
                        في نفس المساحة، لقد تم توليد هذا النص </p> -->
                    </div>
                  </div>
                </b-col>
                <b-col lg="4" md="12" sm="12">
                  <div class="adv-col-div">
                    <img src="../../src/assets/images/landing-page/adv-3.png">
                    <br>
                    <div class="adv-col-hdr">
                      <h5><b>غرف خاصه لتقييم والمتابعة</b></h5>
                      <!-- <p>هذا النص هو مثال لنص يمكن أن يستبدل
                        في نفس المساحة، لقد تم توليد هذا النص </p> -->
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </section>


          <!-- aboutus -->
          <section id="about-us">
            <div class="container about-us-div" >
              <div class="about-us-div-hdr">
                <h3>
                  نبذه عنا
                </h3>
              </div>
              <b-row align-v="center">
                <b-col lg="6" md="12" sm="12">
                  <div class="about-us-col">
                    <h2 class="about-us-header"><b>عن ايجي لايف</b></h2>
                    <br>
                    <p class="pt-2 about-us-text">
                      <ul>
                        <li>
                          علم طفلك أون لاين مع نخبة من المدرسين المحترفين في جميع المواد مع منصه EGS.
                        </li>
                        <br>
                        <li>
                          منصة EGS تتيح مدرسين أون لاين لطفلك من سن 4 سنوات حتي 10 سنوات في جميع المواد. 
                        </li>
                        <br>
                        <li>
                          متشلش هم تعليم ابنك في مرحلة الإبتدائي مع منصة EGS هنوفر لك مدرسين في كل المواد وهو في البيت.
                        </li>
                      </ul>
                      
                      <b-link class="about-us-more" v-if="!aboutUsShowMore" @click="aboutUsShowMore = !aboutUsShowMore">
                        المزيد
                      </b-link>
                      <b-link class="about-us-more" v-else @click="aboutUsShowMore = !aboutUsShowMore">
                        اخفاء
                      </b-link>
                    </p>
                  </div>
                </b-col>
                <b-col lg="6" md="12" sm="12">
                  <div class="about-us-img">
                    <img v-if="!aboutUsShowMore" src="../../src/assets/images/landing-page/about-us.png">
                    <p v-else class="pt-2 about-us-text">
                      <ul>
                        <br>
                        <li>
                          مع منصة EGS طفلك هيتعلم كل حاجه وهو في البيت مع نخبة من المدرسين المحترفين في كل المواد من 1 إبتدائي حتي 6 إبتدائي.
                        </li>
                        <br>
                        <li>
                          منصة EGS بتعمل بنظام Crowd teaching علشان تعلم طفلك صح وتأسسه في أهم مرحلة من عمره.
                        </li>
                        <br>
                        <li>
                          منصة EGS هتهتم بطفلك من سن 4 سنوات حتي 10 سنوات وهتقدم له كل دروسه بطريقة مبسطه وسهله وبإسعار تنافسيه وفي متناول يد الجميع.
                        </li>
                      </ul>
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </section>

          <!-- how-it-work -->
          <section class="how-it-work-section" id="how-it-work">
            <div class="container how-it-work-div" >
              <div class="how-it-work-div-hdr">
                <h3>
                  كيف نعمل ؟
                </h3>
              </div>
              <div class="how-it-work-video" >
                <b-embed type="iframe" aspect="16by9" src="https://www.youtube-nocookie.com/embed/Uz4ez2Bz4ns?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></b-embed>
              </div>
            </div>
          </section>

          <!-- footer -->
          <section class="footer-section">
            <div class="container" >
              <div class="footer-img">
                <img src="../../src/assets/images/landing-page/footer-logo.png">
              </div>
              <div class="footer-nav">
                <b-row>
                  <b-col lg="4" md="12" sm="12">
                    <b-navbar-nav class="nav-items">
                      <b-nav-item href="#EGsLive-features"><b>مميزات المنصة</b></b-nav-item>
                    </b-navbar-nav>
                  </b-col>
                  <b-col lg="4" md="12" sm="12">
                    <b-navbar-nav class="nav-items">
                      <b-nav-item href="#about-us"><b>نبذه عنا</b></b-nav-item>
                    </b-navbar-nav>
                  </b-col>
                  <b-col lg="4" md="12" sm="12">
                    <b-navbar-nav class="nav-items">
                      <b-nav-item href="#how-it-work"><b>كيف نعمل</b></b-nav-item>
                    </b-navbar-nav>
                  </b-col>
                </b-row>
              </div>
            </div>
          </section>
          
          <section>
            <div class="footer-info">
              <div class="container">
                <b-row align-v="center">
                  <b-col lg="6" md="12" sm="12">
                    <div class=" footer-col footer-col-copy-right">
                      <p>
                        جميع الحقوق محفوظة
                        Egslive © 2020
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="6" md="12" sm="12">
                    <div class=" footer-col footer-btn-group">
                      <b-button variant="link" href="https://www.facebook.com/EgsliveS/" class="footer-btn" target="_blank"><img src="../../src/assets/images/landing-page/fb-icon.png"></b-button>
                      <!-- <b-button variant="link" href="https://www.fb.com" class="footer-btn" target="_blank"><img src="../../src/assets/images/landing-page/twitter-icon.png"></b-button> -->
                      <!-- <b-button variant="link" href="https://www.fb.com" class="footer-btn" target="_blank"><img src="../../src/assets/images/landing-page/instagram-icon.png"></b-button> -->
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'Home',
    data() {
      return {
        aboutUsShowMore: false,
      }
    },
    methods: {
      goToLogin() {
        this.$router.push({
          name: "login"
        })
      }
    }
  }
</script>

<style>
  .landing-page-container{
    width: 100% !important;
  }
  .landing-page{
    width: 100%;
  }
  .nav-logo{
    width: 60px;
  }
  .nav-items{
    color: black;
  }
  .nav-btn-1{
    margin-left: 10px;
  }
  .navbar{
    padding: .5rem 7rem !important;
  }

  /* intro */
  .intro-div{
    margin: 100px auto;
  }
  .intro-div .intro-text{
    font-size: 18px;
    width: 70%;
  }
  .intro-div .intro-img img{
    display: block;
    margin: auto;
  }
  .intro-btn {
    margin-top: 20px;
    padding: 0px 30px 10px 50px !important;
  }
  .intro-btn-1{
    margin-left: 20px;
  }

  /* advantages */
  .adv-section{
    background-color: #f8f9fa;
    box-shadow: 4px 5px 6px #dfdfdf;
  }
  .adv-div{
    padding: 70px 0px;
    margin: 90px auto;
    text-align: center;
  }
  .adv-col-div{
    margin-top: 50px;
    padding: 20px;
  }
  .adv-col-div .adv-col-hdr{
    padding-top: 30px;
  }

  
  /* about-us */
  .about-us-div{
    margin: 100px auto;
  }
  .about-us-more{
    float: left;
  }
  .about-us-div-hdr{
    text-align: center;
    margin-bottom: 50px;
  }
  .about-us-div .about-us-text{
    font-size: 18px;
    width: 80%;
  }
  .about-us-div .about-us-img img{
    display: block;
    margin: auto;
  }
  .about-us-btn {
    margin-top: 20px;
    padding: 0px 30px 10px 50px !important;
  }
  .about-us-btn-1{
    margin-left: 20px;
    padding: 10px 20px !important;
  }
  .about-us-btn-2{
    background-color: transparent !important;
    border: unset !important;
  }

  /* how-it-work */
  .how-it-work-div{
    padding: 70px 0px;
    margin: 90px auto;
    text-align: center;
    padding-top: 27px !important;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .how-it-work-image img{
    display: block;
    margin: auto;
  }
  .how-it-work-section{
    background-color: #f8f9fa;
    background-repeat: no-repeat;
    background-image: url('@/assets/images/how-it-work-video.png');
    box-shadow: 4px 5px 6px #dfdfdf;
    background-size: contain;
    background-position: center;
  }
  .how-it-work-video{
    width: 50%;
    margin: auto;
    box-shadow: 3px 4px 23px #6e6e6e;
  }
  .how-it-work-div-hdr{
    text-align: center;
    margin-bottom: 50px;
  }



  /* footer */
  .footer-section{
    margin: 30px auto;
    margin-top: 0px !important;
  }
  .footer-img{
    padding: 10px 20px 40px 20px;
  }
  .footer-img img{
    display: block;
    margin: auto;
    width: 120px;
  }
  .footer-nav{
    text-align: center;
    width: 50%;
    margin: auto;
  }
  .footer-info{
    background-color: #7550ff;
    color: white;
    padding: 13px !important;
    font-size: 13px;
  }
  .footer-btn{
    padding: 0px !important;
    margin-left: 3px;
    margin-right: 3px;
  }
  .footer-btn-group{
    float:left;
  }
  .footer-col-copy-right{
    padding-top: 13px;
  }
  @media (max-width: 991px) {
    .nav-btn{
      margin-top: 10px;
      margin-left: 0px;
    }
    .nav-btn-1{
      margin-left: 0px !important;
    }
    .navbar{
      padding: .5rem 1rem !important;
    }

    /* intro */
    .intro-div{
      margin: 50px auto;
    }
    .intro-div .intro-col{
      padding: 0px 40px;
    }
    .intro-div .intro-header{
      font-size: 23px;
    }
    .intro-div .intro-text{
      font-size: 18px;
      width: 100%;
    }
    .intro-div .intro-img img{
      width: 70%;
      display: block;
      margin: auto;
      margin-top: 40px;
    }

    .about-us-div .about-us-text{
      font-size: 18px;
      width: 100%;
      margin-bottom: 40px;
    }
    .about-us-btns-dib{
      margin-bottom: 50px;
    }
    .about-us-div .about-us-img img{
      width: 70%;
      display: block;
      margin: auto;
      margin-top: 40px;
    }

    /* about-us */
    .about-us-div{
      margin: 50px auto;
    }
    .about-us-div .about-us-col{
      padding: 0px 40px;
    }
    .about-us-div .about-us-header{
      font-size: 23px;
    }
    .about-us-div .about-us-text{
      font-size: 18px;
      width: 100%;
    }
    .about-us-div .about-us-img img{
      width: 70%;
      display: block;
      margin: auto;
      margin-top: 40px;
    }

    /* how-it-work */
    .how-it-work-video{
      width: 90%;
      margin: auto;
      box-shadow: 3px 4px 23px #6e6e6e;
    }

    /* footer */
    .footer-col{
      text-align: center;
      float: center;
    }
    .footer-btn-group{
      margin: auto;
      float: unset;
    }
  }
  @media (max-width: 950px) {
    .landing-page{
      margin: auto;
    }
    .footer-nav{
      text-align: center;
      width: 70%;
      margin: auto;
    }
  }
  @media (max-width: 650px) {
    .landing-page{
      width: 100%;
      margin: auto;
    }
    .intro-div .intro-col{
      padding: 0px 10px;
    }
    .about-us-div .about-us-col{
      padding: 0px 10px;
    }
  }
</style>