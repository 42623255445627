<template>
  <div class="login-page">
    <div class="">
      <div class="panel panel-default login-panel">
        <div>
          <teacher-login></teacher-login>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import teacherLogin from "../components/teacherLogin.vue"

  export default {
    components: {
      teacherLogin
    }
  }
</script>

<style>
  .login-page{
    width: 100% !important;
    height: 95vh !important;
  }
  .login-panel{
    width: 100%;
  }
  .panel-heading{
    text-align: center;
  }
  .form-footer{
    padding-top: 20px;
  }
  .login-btn{
    display: block !important;
    margin: auto !important;
  }
  @media (max-width: 950px) {
    .login-panel{
      margin: auto;
    }
  }
  @media (max-width: 650px) {
    .login-panel{
      width: 100%;
      margin: auto;
    }
  }
</style>