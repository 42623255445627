<template>
  <div>
    <div>
      <b-row>
        <b-col lg="5" md="5" class="login-img-col">
          <div class="login-img">
            <div class="login-r">
              <img src="../../src/assets/images/logo.png">
              <div class="login-text">
                <p>مرحبًا بك في ايجي لايف</p>
                <br>
                <p>سجل دخولك الآن </p>

              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="7" md="7">
          <div class="panel login-data">
              <div class="panel-heading">
                <img class="login-panel-logo" src="../../src/assets/images/logo-dark.png">
                <h4><strong>ادخل البيانات لتسجيل الدخول</strong></h4>
                <br>
                <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
                  {{alertText}}
                </b-alert>
                <br><br><br><br>
              </div>  
            <b-form @submit.stop.prevent="onSubmit">
              <div class="bv-example-row">
                <b-row>
                  <b-col lg="12" md="12">
                    <b-form-group id="group-teacher-user_name" label="اسم المستخدم او البريد الالكتروني" label-for="teacher-user_name">
                      <b-form-input
                        id="teacher-user_name"
                        name="teacher-user_name"
                        v-model="$v.form.user_name.$model"
                        :state="validateState('user_name')"
                        aria-describedby="input-user_name"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-user_name">
                        اسم المستخدم مطلوب
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <b-form-group id="group-teacher-password" label="كلمة المرور" label-for="teacher-password">
                      <b-form-input
                        id="teacher-password"
                        name="teacher-password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="input-password"
                        type="password"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-password" v-if='!$v.form.password.required'>
                        كلمة المرور مطلوبه
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" md="12">
                    <div class="form-footer">
                      <b-button class="login-btn" type="submit" variant="primary"> تسجيل الدخول</b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { validationMixin } from "vuelidate";
  import { required, minLength, maxLength, alphaNum, email, numeric, sameAs, password} from "vuelidate/lib/validators";
  import axios from 'axios';
  import { sendRequest }  from "../utils/RequestHelper.js"

  export default {
    mixins: [validationMixin],
    data() {
      return {
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        form: {
          user_name: null,
          password: null,
        }
      }
    },
    validations: {
      form: {
        user_name: {
          required
        },
        password: {
          required,
        },
      }
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.login();
      },
      login() {
        axios.post(process.env.VUE_APP_API_URL + '/teacher/login', this.form)
        .then((response) => {
          this.alertType = "success"
          this.alertText = "تم  تسجيل الدخول بنجاح "
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          store.commit("SET_TOKEN", response.data.data.token);
          store.dispatch("SET_ACCOUNT_TYPE", "teacher");
          var successCallback = (response) => {
            store.dispatch("SET_USER_ID", response.data.data.id);
            store.dispatch("SET_USERNAME", response.data.data.full_name);
            store.dispatch("SET_IMAGE", response.data.data.image);
            this.$router.push({
              name: 'home'
            })
          };
          var errorCallback = (error) => {
            this.isLoading = false;
            this.alertType = "danger"
            this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('get', '/teacher/get-account', true, null , successCallback, errorCallback);
          
        })
        .catch((error) => {
          if(error.response.status == 422) {
            this.alertType = "danger"
            this.alertText = error.response.data.error
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          }
        });
      },
    },
    created() {
      if(store.getters.authToken) {
        this.$router.push({
          name: 'home'
        })
      }
    }
  }
</script>

<style>
  .panel-heading{
    text-align: center;
  }
  .form-footer{
    padding-top: 20px;
  }
  .login-btn{
    display: block !important;
    margin: auto !important;
  }
  .login-img{
    width: 100%;
    height: 98vh !important;
    background-color: #7550FF;
  }
  .login-img img {
    width: 60%;
    margin: auto;
    display: block;
  }
  .login-img p {
    margin: auto;
    display: block;
    text-align: center;
    color: white;
    font-size: 18px;
  }
  .login-r{
    margin: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .login-data{
    padding: 80px 200px !important;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .login-panel-logo{
    display: none;
    width: 80px;
  }
  @media (max-width: 1100px) {
    .login-data{
      padding: 80px 20px !important;
    }
  }
  @media (max-width: 767px) {
    .login-data{
      position: inherit;
      padding-right: 40px !important;
    }
    .login-img-col{
      display: none;
    }
    .login-panel-logo{
      display: block;
      padding-bottom: 30px;
      margin: auto;
    }
  }
</style>