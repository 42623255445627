const mutations  = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERNAME(state, user_name) {
    state.user_name = user_name;
  },
  SET_ACCOUNT_TYPE(state, account_type) {
    state.account_type = account_type;
  },
  LOGOUT(state) {
    state.token = null;
    state.user_name = null;
    state.account_type = null;
    state.image = null;
    state.userID = null;
  },
  SET_IMAGE(state, image) {
    state.image = image;
  },
  SET_USER_ID(state, userID) {
    state.userID = userID;
  },
}
export default mutations 
