<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="rooms-layout">
      <b-row>
        <b-col lg="9" md="12" sm="12">
          <div class="public-rooms">
            <div class="div-hdr" >
              <h5><b>{{LessonData.name}}</b></h5>
            </div>
            <div class="rooms-body">
              <div>
                <div class="classes">
                  <div class="video-div">
                    <div style="color: red; text-align: center" v-if="invalidURL">
                      <h4>مسار الفيديو غير صحيح</h4>
                      <p>يجب ان يحتوي مسار الفيديو على <u>كود الفيديو</u></p>
                    </div>
                    <b-embed v-if="!invalidURL" type="iframe" aspect="16by9" :src="LessonData.youtube_video_path" allowfullscreen ></b-embed>
                    <div class="video-details pt-2" v-if="!invalidURL">
                      <p>
                        {{LessonData.description}}
                      </p>
                    </div>
                  </div>
                  <div class="div-hdr lessons-div">
                    <h5><b>التعليقات</b></h5>
                  </div>
                  <div class="text-center text-danger my-2" v-show="isLoading">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <div class="class-col" v-if="comments.length == 0" v-show="!isLoading">
                    <div class="pt-5 no-content" style="text-align:center">
                      <h5> لا يوجد تعليقات</h5>
                    </div>
                  </div>
                  <div class="comments-col" v-else>
                    <div class="class-col" v-for="(comment, index) in comments" :key="index" v-show="!isLoading">
                      <b-row align-v="center">
                        <b-col lg="12" md="12" sm="12">
                          <b-media vertical-align="center" class="pr-2 class-col-click">
                            <h5 class="mt-0 mb-2">{{comment.user_name}}</h5>
                            <p class="mb-0">{{comment.comment}}</p>
                          </b-media>
                        </b-col>
                        <b-col lg="5" md="5" sm="12">
                          <div class="action-buttons">
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="3" md="12" sm="12">
          <div class="connects-users">
            <div class="div-hdr lessons-div">
              <h5><b>الطلاب <span>( {{connectedUsers.length}} )</span></b></h5>
            </div>
            <div class="text-center text-danger my-2" v-show="connectedUserisLoading">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <div class="class-col connects-col" v-if="connectedUsers.length == 0" v-show="!connectedUserisLoading">
              <b-row align-v="center">
                <b-col lg="12" md="12" sm="12">
                  <div class="pt-5 no-content" style="text-align:center">
                    <h5> لا يوجد طلاب </h5>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="connections-col" v-else>
              <div class="class-col connected-users-col"  v-for="(user, index) in connectedUsers" :key="index" v-show="!connectedUserisLoading">
                <b-row align-v="center">
                  <b-col lg="12" md="12" sm="12">
                    <b-media vertical-align="center">
                      <template v-slot:aside>
                        <span class="user-connect-flag">
                          <i v-if="user.isJoin == '1'" class="fas fa-circle fa-circle-online"></i>
                          <i v-else class="fas fa-circle fa-circle-offline"></i>
                        </span>
                        <b-avatar class="image" size="10px" :src="user.image"></b-avatar>
                      </template>
                      <p class="pt-3">{{user.user_name}}</p>
                    </b-media>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"
  import { fb, db} from '../firebase/firebase.js';

  export default {
    name: 'Home',
    data() {
      return {
        isLoading: false,
        connectedUserisLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        LessonData: {},
        comments: [],
        connectedUsers: [],
        invalidURL: false,
        getData: null,
        getUsersInterval: null
      }
    },
    beforeDestroy () {
      clearInterval(this.getData)
    },
    destroyed() {
      clearInterval(this.getUsersInterval)
    },
    methods: {
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },
      loadComments() {
        this.isLoading = true;
        var showNewComment = this.showNewComment;
        var successCallback = (response) => {
          var lessonID = this.LessonData.id;
          this.comments = response.data.data;
          var getComments = db.ref('comments/' + lessonID);
          getComments.on('value', function(snapshot) {
            showNewComment(snapshot.val())
          });
          this.comments.shift();
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/show-comments/' + this.$route.params.lessonID, true, null , successCallback, errorCallback); 
      },
      showNewComment(a) {
        if (a) {
          var indexOfSplice = a.lastIndexOf(':');
          var text = a.slice(0, indexOfSplice);
          var user = a.slice(indexOfSplice+1);
          var comment = {
            user_name: user,
            comment: text
          }
          this.comments.unshift(comment)
        }
      },
      loadConnectedUsers() {
        this.connectedUserisLoading = true;
        var lessonID = this.LessonData.id;
        var successCallback = (response) => {
          this.connectedUsers = response.data;
          this.connectionsDic = {};
          var index = 0;
          // key: userID, value: index in array=>connectedUsers
          this.connectedUsers.forEach(user => {
            this.connectionsDic[user.id] = index;
            index++;
          });
          this.connectedUserisLoading = false;
          var showNewConnections = this.showNewConnections;
          var getConnections = db.ref('connects/' + lessonID);
          getConnections.on('value', function(snapshot) {
            showNewConnections(snapshot.val())
          });
        };
        var errorCallback = () => {
          this.connectedUserisLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', '/teacher/show-students/' + this.LessonData.room_id + '/' + this.$route.params.lessonID, true, null , successCallback, errorCallback);
      },
      showNewConnections(a) {
        if (a) {
          var indexOfSplice = a.lastIndexOf(':');
          var userAndID = a.slice(0, indexOfSplice);
          var status = a.slice(indexOfSplice+1);
          var indexOfUSer = userAndID.lastIndexOf('?');
          var username = userAndID.slice(0, indexOfUSer);
          var id = userAndID.slice(indexOfUSer+1);
          var connect = {
            userID: id,
            username: username,
            status: status
          }
          if(this.connectedUsers[this.connectionsDic[connect.userID]])
            this.connectedUsers[this.connectionsDic[connect.userID]].isJoin = connect.status;
        }
      },
      loadData() {
        this.isLoading = true;
        var successCallback = (response) => {
          this.LessonData = response.data.data;
          this.loadComments();
          this.loadConnectedUsers();
          this.isLoading = false;
          if(this.LessonData.youtube_video_path == null){
            this.invalidURL = true
          }
          if(!this.LessonData.youtube_video_path.includes("https://www.youtube.com/embed/")) {
            this.invalidURL = true
          }
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = "خطأ .. حاول مرة اخرى لاحقا"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.$router.push({
            name: "home"
          })
        };
        sendRequest('get', '/teacher/lives/' + this.$route.params.lessonID, true, null , successCallback, errorCallback);
      }
    },
    created() {
      if(store.getters.authToken == null) {
        store.commit("SET_TOKEN", null);
        store.commit("SET_USERNAME", null);
        this.$router.push({
          name: 'login'
        })
      }
      this.isLoading = true;
      this.loadData(); 
    }
  }
</script>
<style scoped>
  .rooms-layout{
    padding: 30px 30px;
    background-color: #F3F5F9;
    box-shadow: inset 0px 3px 10px 1px #DFDFD4;
  }
  .div-hdr{
    padding: 0px 10px;
  }
  .div-hdr h5{
    display: inline !important;
  }
  .div-hdr span, .div-hdr .btn {
    float: left;
  }
  .div-hdr .title{
    color: rgb(155, 155, 155);
    font-size: 15px;
    padding-top: 10px;
  }
  .classes{
    margin-top: 50px;
  }
  .video-details{
    font-weight: bold;
    font-size: 15px;
    width: 70%;
    margin-top: 20px;
  }
  .lessons-div{
    margin-bottom: 20px;
    padding: 0px;
  }
  .class-col{
    margin: 15px 0px;
    padding: 20px 10px 20px 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  .class-col .image{
    margin-left: 15px;
    width: 40px !important;
    height: 40px !important;
  }
  .class-col h5{
    font-size: 16px;
    font-weight: 600;
    padding-top: 7px;
  }
  .class-col p{
    font-size: 14px;
    color: rgb(155, 155, 155);
  }
  .class-col .action-buttons{
    float: left;
  }
  .delete-icon{
    color: #5e3fd0;
  }
  .spinner-border{
    color: #7550FF;
  }
  .no-content{
    color: #29ace4;
  }
  .no-content h4{
    padding-top: 20px;
    font-size: 30px;
    color: #29ace4;
  }
  .connects-col{
    min-height: 274px !important;
  }
  .connected-users-col{
    padding: 7px;
    padding-top: 10px !important;
  }
  .user-connect-flag{
    padding-left: 10px;
    padding-top: 6px;
  }
  .fa-circle{
    font-size: 12px;
  }
  .fa-circle-online{
    color: rgb(2, 179, 2);
  }
  .fa-circle-offline{
    color: rgb(170, 170, 170);
  }
  @media (max-width: 767px) {
    .room-col{
      padding-left: 20px;
    }
    .room-col .action-buttons{
      margin-top: 20px;
    }
    .class-col{
      padding-left: 20px;
    }
    .class-col .action-buttons{
      margin-top: 20px;
    }
    .video-details{
      width: 100%;
    }
  }
  .video-details{
    font-weight: bold;
    font-size: 15px;
    width: 70%;
    margin-top: 20px;
    padding-right: 5px;
    margin-bottom: 40px;
  }
  .connections-col{
    max-height: 1200px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (max-width: 767px) {
    .video-details{
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .div-hdr{
      padding: 0px 10px;
    }
    .rooms-layout{
      margin-top: 50px;
    }
  } 
</style>
