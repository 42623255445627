// Firebase v9+ (Modular SDK)
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Firebase configuration
const config = {
  apiKey: "AIzaSyDlJNlsfXHRXeVbUBVti1AvKgFxDA3opFI",
  authDomain: "egslive-282521.firebaseapp.com",
  databaseURL: "https://egslive-282521.firebaseio.com",
  projectId: "egslive-282521",
  storageBucket: "egslive-282521.appspot.com",
};

// Initialize Firebase
const fb = initializeApp(config);
const db = getDatabase(fb);

export { fb, db };
